@import "./vars.scss";

section.skills {
  @include splitter-glide;
  background: linear-gradient(183deg, $primary, transparent 30%);

  .content {
    max-width: 30rem;
    margin: 0 auto;
    padding: 3rem 0;

    p.description {
      margin-bottom: .5rem;
    }
  }

  .skills-container {
    position: relative;
    background: darken($secondary, 10%);
    border-radius: 0.5rem;
    overflow: hidden;
    box-shadow: 0 0 2rem 0 rgba(0, 0, 0, .35), 0 .1rem .2rem 0 rgba(0, 0, 0, .3);
  }

  .legend-container {
    padding: 1rem;

    @include media(lt-md) {
      padding: 0.5rem;
    }

    .color {
      $size: 1em;

      width: $size;
      height: $size;
      border-radius: $size;
      margin-right: 0.25rem;
    }
  }

  .skill-node {
    fill: rgba(255, 255, 255, 0.8);
    font-size: 0.4rem;
    text-transform: capitalize;

    .skill-scaler {
      transition: opacity 0.3s;
    }
  }

  .axis-label {
    font-size: 0.5rem;
    position: absolute;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
    border-radius: 100rem;
    padding: 0.25rem 1rem;
    background: white;

    &.axis-x {
      bottom: 0.2rem;
      left: 50%;
      transform: translateX(-50%);
    }

    &.axis-y {
      top: 50%;
      left: -1.5rem;
      transform: rotate(-90deg);
    }
  }

  .pills {
    a {
      text-decoration: none;
    }
  }
}
