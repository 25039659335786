section.skills {
  background: linear-gradient(183deg, #29d5d2, #0000 30%);
  margin-top: -15vh;
  padding-top: 15vh;
}

section.skills .content {
  max-width: 30rem;
  margin: 0 auto;
  padding: 3rem 0;
}

section.skills .content p.description {
  margin-bottom: .5rem;
}

section.skills .skills-container {
  background: #1a1125;
  border-radius: .5rem;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 2rem #00000059, 0 .1rem .2rem #0000004d;
}

section.skills .legend-container {
  padding: 1rem;
}

@media screen and (width <= 1000px) {
  section.skills .legend-container {
    padding: .5rem;
  }
}

section.skills .legend-container .color {
  border-radius: 1em;
  width: 1em;
  height: 1em;
  margin-right: .25rem;
}

section.skills .skill-node {
  fill: #fffc;
  text-transform: capitalize;
  font-size: .4rem;
}

section.skills .skill-node .skill-scaler {
  transition: opacity .3s;
}

section.skills .axis-label {
  background: #fff;
  border-radius: 100rem;
  padding: .25rem 1rem;
  font-size: .5rem;
  position: absolute;
  box-shadow: 0 .25rem .5rem #0000001a;
}

section.skills .axis-label.axis-x {
  bottom: .2rem;
  left: 50%;
  transform: translateX(-50%);
}

section.skills .axis-label.axis-y {
  top: 50%;
  left: -1.5rem;
  transform: rotate(-90deg);
}

section.skills .pills a {
  text-decoration: none;
}
/*# sourceMappingURL=index.0c1d706d.css.map */
